<template>
  <v-row>
    <v-col>
      <div class="mb-5">
        <v-row
          align="stretch"
          no-gutters
        >
          <v-col class="grow">
            <SearchKeyword
              :filter="filter"
              :searchable="['device name','device ID', 'device EUI']"
            />
          </v-col>
          <v-col class="shrink mt-1 ml-3">
            <DeviceListFilter :filter="filter" />
          </v-col>
        </v-row>
      </div>

      <v-card>
        <v-toolbar flat>
          <v-icon>mdi-developer-board</v-icon>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-toolbar-title class="d-none d-sm-flex align-center">
            <span>{{ camelToSentence($options.name) }}</span>
          </v-toolbar-title>
          <v-spacer />

          <v-btn
            text
            class="mr-1"
            @click="$refs.formExport.dialog = true"
          >
            <v-icon left>
              mdi-file-export
            </v-icon>
            Export
          </v-btn>

          <v-btn
            v-if="user.is_superadmin"
            color="primary"
            dark
            @click="openForm(null)"
          >
            Add
          </v-btn>
        </v-toolbar>

        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="meta.total"
          :loading="loading"
          disable-sort
          mobile-breakpoint="0"
          :footer-props="footerProps"
        >
          <!-- name -->
          <template v-slot:item.name="{ item }">
            <router-link
              :to="`/devices/${item.id}`"
              class="body-1 font-weight-bold no-underline"
              v-html="highlightString(item.name,routeQuery.keyword)"
            />
          </template>
          <!-- device_id -->
          <template v-slot:item.device_id="{ item }">
            <span v-html="highlightString(item.device_id,routeQuery.keyword)" />
          </template>
          <!-- type -->
          <template v-slot:item.type="{ item }">
            <span>{{ underscoreToSpace(item.type) }}</span>
          </template>
          <!-- organization -->
          <template v-slot:item.organization="{item}">
            <router-link
              v-if="item.organization"
              :to="`/organizations/${item.organization.id}`"
              class="body-1 font-weight-bold no-underline"
            >
              {{ item.organization.name }}
            </router-link>
          </template>
          <!-- latest uplink -->
          <template v-slot:item.last_uplink_at="{item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-chip
                  class="ma-2"
                  :color="checkDeviceConnection(item.last_uplink_at)? 'success' : 'error'"
                  small
                  v-on="on"
                >
                  {{ checkDeviceConnection(item.last_uplink_at) ? 'Online' : 'Offline' }}
                </v-chip>
              </template>
              <span v-if="item.last_uplink_at">{{ item.last_uplink_at | formatDateFromNow }}</span>
              <span v-else>No data</span>
            </v-tooltip>
          </template>
          <!-- actions -->
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  class="mr-2"
                  v-on="on"
                  @click.stop="showInfo(item)"
                >
                  <v-icon>mdi-information</v-icon>
                </v-btn>
              </template>
              <span>Info</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  class="mr-2"
                  v-on="on"
                  @click="openForm(item)"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  color="error"
                  class="mr-2"
                  v-on="on"
                  @click="deleteItem(item)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>

      <DeviceInfo
        ref="deviceInfo"
        :device-detail="deviceDetail"
      />

      <DeviceForm
        ref="formModal"
        :item="item"
        @reload-data="handlingReload"
      />

      <DeviceExportData
        ref="formExport"
        multiple-devices
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { underscoreToSpace, checkDeviceConnection } from '@/utils/utils';
import DatalistMixin from '../../mixins/DatalistMixin';
import DeviceForm from './DeviceForm.vue';
import DeviceInfo from './DeviceInfo.vue';
import DeviceListFilter from './DeviceListFilter.vue';
import DeviceExportData from './DeviceExportData.vue';
import SearchKeyword from '../../components/SearchKeyword.vue';

export default {
  name: 'DeviceList',
  components: {
    DeviceListFilter, DeviceForm, DeviceInfo, DeviceExportData, SearchKeyword,
  },
  mixins: [DatalistMixin],

  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name',
          class: 'min-width-15',
        },
        {
          text: 'Device ID',
          value: 'device_id',
          class: 'min-width-10',
        },
        {
          text: 'Type',
          value: 'type',
          class: 'min-width-10',
        },
        {
          text: 'Integration',
          value: 'integration.name',
        },
        {
          text: 'Organization',
          value: 'organization',
          class: 'min-width-10',
        },
        {
          text: 'Status',
          value: 'last_uplink_at',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '170px',
        },
      ],
      deviceDetail: {},

      filter: {
        page: 1,
        keyword: null,
        organization_id: null,
        type: null,
        status: null,
      },
      filterMenu: false,
    };
  },

  computed: {
    ...mapGetters(['user']),
  },

  created() {
    if (!this.user.is_superadmin) {
      // hide 'organization' header, when not superadmin
      this.headers = this.headers.filter((h) => h.value !== 'organization');
      // hide 'action' header, when not superadmin
      this.headers = this.headers.filter((h) => h.value !== 'actions');
      // non superadmin cant filter by organization
      delete this.filter.organization_id;
    } else {
      // superadmin load all organizations
      this.$store.dispatch('getOrganizations');
    }
  },

  mounted() {
    // event from delete data
    this.$on('reload-data', () => {
      this.handlingReload();
    });
  },

  methods: {
    checkDeviceConnection,
    underscoreToSpace,

    getData() {
      let lastUplinkAfter = null;
      let lastUplinkBefore = null;
      if (this.filter.status === 'online') {
        lastUplinkAfter = '1 hour ago';
      } else if (this.filter.status === 'offline') {
        lastUplinkBefore = '1 hour ago';
      } else {
        lastUplinkAfter = null;
        lastUplinkBefore = null;
      }

      return this.$api.getDevices({
        ...this.filter,
        last_uplink_after: lastUplinkAfter,
        last_uplink_before: lastUplinkBefore,
      });
    },

    deleteData(devId) {
      return this.$api.deleteDevice(devId);
    },

    showInfo(item) {
      this.deviceDetail = { ...item };
      this.$refs.deviceInfo.dialog = true;
    },

    handlingReload(event = null) {
      // update vuex store - no paginate
      this.$store.dispatch('getDevices', {
        forceGet: true,
      });

      // update local store - with paginate
      this.getItem();
    },
  },
};
</script>
