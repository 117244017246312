<template>
  <v-menu
    v-model="filterMenu"
    :close-on-content-click="false"
    origin="top right"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        large
        title="Filter Parameter"
        v-on="on"
      >
        <v-badge
          color="red"
          overlap
          dot
          :value="isFiltered"
        >
          <v-icon>mdi-filter</v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-card width="500px">
      <v-card-title>Filter by</v-card-title>
      <v-card-text class="mb-0 pb-0">
        <v-autocomplete
          v-if="user.is_superadmin"
          v-model="filter.organization_id"
          :items="organizations"
          clearable
          item-text="name"
          item-value="id"
          label="Organization"
          prepend-icon="mdi-domain"
          @click:clear="setPropertyToNull(filter,'organization_id')"
        />
        <v-autocomplete
          v-model="filter.type"
          :items="deviceTypeOptions"
          clearable
          prepend-icon="mdi-chip"
          label="Types"
          @click:clear="setPropertyToNull(filter,'type')"
        />
        <v-select
          v-model="filter.status"
          :items="statusOptions"
          clearable
          prepend-icon="mdi-link-variant"
          label="Status"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />

        <v-btn
          text
          @click="filterMenu = false"
        >
          Close
        </v-btn>
        <v-btn
          color="secondary"
          :to="routeGenerator({reset:true})"
          @click="filterMenu=false"
        >
          Reset
        </v-btn>
        <v-btn
          color="primary"
          :to="routeGenerator()"
          @click="filterMenu=false"
        >
          Apply
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import ListFilterMixin from '../../mixins/ListFilterMixin';
import { deviceTypeOptions } from '../../utils/shared';

export default {
  name: 'DeviceListFilter',
  mixins: [ListFilterMixin],

  data() {
    return {
      statusOptions: [
        {
          value: 'online',
          text: 'Online Devices',
        },
        {
          value: 'offline',
          text: 'Offline Devices',
        },
      ],
    };
  },

  computed: {
    deviceTypeOptions() {
      return deviceTypeOptions;
    },
  },
};
</script>
